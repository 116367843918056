import React, { useEffect, useState } from 'react';
import axios from '../../../services/axios';
import { useSelector } from 'react-redux';

function AttendanceTable() {
    const [attendanceData, setAttendanceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalDaysPresent, setTotalDaysPresent] = useState(0);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [hoveredCell, setHoveredCell] = useState(null);

    const { startDate } = useSelector(state => state.dateRange);

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const isSunday = (day) => {
        if (!startDate) return false;
        const currentDate = new Date(startDate);
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const date = new Date(currentYear, currentMonth, day);
        return date.getDay() === 0;
    };

    const calculateBonus = (baseBonus, totalRevenue) => {
        if (!baseBonus || !totalRevenue) return 0;
        const bonusPercentage = parseFloat(baseBonus.replace('%', '')) / 100 || 0;
        const bonusInVND = bonusPercentage * totalRevenue * 23500;
        return Math.round(bonusInVND);
    };

    const calculateTotalSalary = (baseSalary, totalDaysPresent, totalDaysInMonth, bonus) => {
        if (!baseSalary) return 0;
    
        const numericBaseSalary = parseFloat(baseSalary.replace(/,/g, '').replace(' VNĐ', '')) || 0;
    
        const sundays = Array.from({ length: totalDaysInMonth }, (_, i) => new Date(currentYear, currentMonth - 1, i + 1))
                             .filter(date => date.getDay() === 0).length;
    
        const workingDays = totalDaysInMonth - sundays;
    
        const dailySalary = numericBaseSalary / workingDays;
        const totalSalary = dailySalary * totalDaysPresent + bonus;
    
        return Math.round(totalSalary);
    };
    
    useEffect(() => {
        if (!startDate) return;

        const currentDate = new Date(startDate);
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        const apiUrl = `/api/chamcong/${currentMonth}/${currentYear}/`;
        axios.get(apiUrl)
            .then(response => {
                const data = response;
                setAttendanceData(data);
                const total = data.attendance_days.reduce((sum, day) => {
                    let count = 0;
                    if (day.morning === 'x') count += 1;
                    else if (day.morning === 'x/2') count += 0.5;
                    if (day.afternoon === 'x') count += 1;
                    else if (day.afternoon === 'x/2') count += 0.5;
                    return sum + count;
                }, 0);
                setTotalDaysPresent(total);
                setLoading(false);
            })
            .catch(() => {
                setError("Error fetching data");
                setLoading(false);
            });
    }, [startDate]);

    const handleOpenModal = (day, shift) => {
        setSelectedDay(day);
        setSelectedShift(shift);
        setShowModal(true);
    };

    const handleSubmit = (shiftValue) => {
        if (!startDate || !selectedDay) return;

        const currentDate = new Date(startDate);
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        axios.post('/api/update-chamcong/', {
            date: `${currentYear}-${currentMonth}-${selectedDay}`,
            [selectedShift]: shiftValue,
        })
            .then(() => {
                setAttendanceData(prevData => {
                    const updatedData = { ...prevData };
                    const updatedDays = [...prevData.attendance_days];

                    if (selectedShift === 'morning_shift') {
                        updatedDays[selectedDay - 1].morning = shiftValue === 1 ? 'x' : (shiftValue === 0.5 ? 'x/2' : '');
                    } else if (selectedShift === 'afternoon_shift') {
                        updatedDays[selectedDay - 1].afternoon = shiftValue === 1 ? 'x' : (shiftValue === 0.5 ? 'x/2' : '');
                    }

                    updatedData.attendance_days = updatedDays;

                    const updatedTotal = updatedDays.reduce((sum, day) => {
                        let count = 0;
                        if (day.morning === 'x') count += 1;
                        else if (day.morning === 'x/2') count += 0.5;
                        if (day.afternoon === 'x') count += 1;
                        else if (day.afternoon === 'x/2') count += 0.5;
                        return sum + count;
                    }, 0);

                    setTotalDaysPresent(updatedTotal);
                    return updatedData;
                });

                setShowModal(false);
            })
            .catch((error) => {
                console.error("Error updating attendance data:", error);
            });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!attendanceData || !attendanceData.attendance_days) {
        return <div>No data available</div>;
    }

    const currentDate = new Date(startDate);
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);

    let previousMonth = currentMonth - 1;
    let previousYear = currentYear;
    if (previousMonth === 0) {
        previousMonth = 12;
        previousYear -= 1;
    }

    const bonus = calculateBonus(attendanceData.base_bonus, attendanceData.total_revenue);
    const totalSalary = calculateTotalSalary(
        attendanceData.base_salary,
        totalDaysPresent,
        daysInMonth,
        bonus
    );

    return (
        <div className="w-screen md:w-screen lg:w-full relative shadow-md overflow-auto">
            <table className="text-sm w-full text-center rtl:text-right text-gray-500 dark:text-gray-400 mt-5">
                <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" rowSpan="2" className="border bg-white">Shift</th>
                        {[...Array(daysInMonth).keys()].map(day => (
                            <th
                                key={day}
                                colSpan="1"
                                className={`border px-4 py-2 ${isSunday(day + 1) ? 'bg-yellow-200' : ''}`}
                            >
                                {day + 1}
                            </th>
                        ))}
                        <th scope="col" rowSpan="2" className="border">Tổng số công</th>
                        {/* <th scope="col" rowSpan="2" className="border">{`Bonus (Tháng ${previousMonth}/${previousYear})`}</th>
                        <th scope="col" rowSpan="2" className="border">Tổng lương (VNĐ)</th> */}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 uppercase text-xs text-gray-700 font-semibold bg-white">Sáng</td>
                        {attendanceData.attendance_days.map((day, idx) => (
                            <td
                                key={idx}
                                className={`border text-center text-xs font-semibold ${isSunday(idx + 1) ? 'bg-yellow-200' : ''} ${
                                    hoveredCell === `${idx}-morning` ? 'bg-white text-white' : ''
                                }`}
                                onMouseEnter={() => setHoveredCell(`${idx}-morning`)}
                                onMouseLeave={() => setHoveredCell(null)}
                                onClick={() => handleOpenModal(idx + 1, 'morning_shift')}
                            >
                                {day.morning}
                            </td>
                        ))}
                        <td className="border px-4 py-2 font-bold text-blue-600 bg-white" rowSpan="2">{totalDaysPresent}</td>
                        {/* <td className="border px-4 py-2 font-bold text-blue-600 bg-white" rowSpan="2">{bonus.toLocaleString('vi-VN')}₫</td>
                        <td className="border px-4 py-2 font-bold text-blue-600 bg-white" rowSpan="2">{totalSalary.toLocaleString('vi-VN')}₫</td> */}
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 uppercase text-xs text-gray-700 font-semibold bg-white">Chiều</td>
                        {attendanceData.attendance_days.map((day, idx) => (
                            <td
                                key={idx}
                                className={`border text-center text-xs font-semibold ${isSunday(idx + 1) ? 'bg-yellow-200' : ''} ${
                                    hoveredCell === `${idx}-afternoon` ? 'bg-white text-white' : ''
                                }`}
                                onMouseEnter={() => setHoveredCell(`${idx}-afternoon`)}
                                onMouseLeave={() => setHoveredCell(null)}
                                onClick={() => handleOpenModal(idx + 1, 'afternoon_shift')}
                            >
                                {day.afternoon}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75" style={{ pointerEvents: 'auto' }}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Chọn công cho ngày {selectedDay}/{currentMonth}/{currentYear}
                                </h3>
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-5">
                                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    Chọn số công cho ca {selectedShift === 'morning_shift' ? 'Sáng' : 'Chiều'}.
                                </p>
                                <div className="flex flex-col items-center justify-center space-y-2 mt-5">
                                    <div className='w-full' onClick={() => handleSubmit(1)}>
                                        <label className="inline-flex items-center justify-between w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                                            <div className="block">
                                                <div className="w-full text-md font-semibold">Đủ công</div>
                                                <div className="w-full text-sm text-gray-500 dark:text-gray-400">Đầy đủ ca</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className='w-full' onClick={() => handleSubmit(0.5)}>
                                        <label className="inline-flex items-center justify-between w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                                            <div className="block">
                                                <div className="w-full text-md font-semibold">Nửa công</div>
                                                <div className="w-full text-sm text-gray-500 dark:text-gray-400">1/2 ca</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className='w-full' onClick={() => handleSubmit(0)}>
                                        <label className="inline-flex items-center justify-between w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                                            <div className="block">
                                                <div className="w-full text-md font-semibold">Hủy công</div>
                                                <div className="w-full text-sm text-gray-500 dark:text-gray-400">Hủy</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AttendanceTable;
